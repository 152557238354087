<template>
  <div>
    <img class="u-gyqm_banner" src="/image/about/banner_gywm.png" alt="" />
  </div>
  <router-view></router-view>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.u-gyqm_banner {
  width: 100%;
}
</style>
